* {
  max-width: 100vw;
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  
  box-align: center;

  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-style: none;
  -moz-overflow-style: none;
}


/* Custom Scroll */
::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@supports (scrollbar-color: red blue) {
  *{
      scrollbar-color: transparent transparent;
      scrollbar-width: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
